.login-form {
  &__submit-button {
    @media (min-width: $screen-mobile-landscape) {
      margin-right: 16px;
    }
  }

  &__link {
    margin-top: 16px;
  }

  &__group {
    @media (min-width: $screen-tablet-portrait) {
      max-width: 364px;
    }
  }

  &__placeholder {
    @include componentPreloader() {
      height: auto;
    }

    .form__group--row-button {
      @media (min-width: $screen-desktop) {
        display: flex;
      }
    }
  }

  .checkbox__label a {
    color: $textBaseColor;
  }
}
