.login-page {
  &__headline {
    font-size: 21px;
    line-height: 115%;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 32px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
  }

  &__info {
    font-size: $fontSizeXSmall;
    line-height: 130%;
    margin: 8px 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeSmall;
      margin: 8px 0 24px;
    }
  }

  &__column {
    margin-bottom: 50px;
  }

  &__info-container {
    margin-top: 25px;
  }

  &__register-button {
    @media (min-width: $screen-tablet-portrait) and (max-width: $screen-tablet-landscape) {
      padding: 0 16px 0 24px;
    }
  }
}
